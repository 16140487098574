<template>
  <super-admin-page-card
    pageName="Update FI Name"
    iconClass="cuis-industry card-icon-font"
  >
    <div>
      <div class="logo-upload">
        <div class="row">
          <div class="col-sm-6">
            <div class="row mb-4">
              <div class="col-sm-4 align-self-center">
                Select Institution:
              </div>
              <div class="col-sm-8 pl-0">
                <multiselect
                  v-model="selectedInstitution"
                  label="full_name"
                  track-by="id"
                  placeholder="Type FI name to search"
                  :options="institutions"
                  :multiple="false"
                  :searchable="true"
                  :loading="isLoading"
                  :internal-search="false"
                  :limit="3"
                  :show-no-results="true"
                  :hide-selected="true"
                  @search-change="searchInstitutions"
                  class="institution-select"
                >
                  <span slot="noResult">
                    No matching institutions found...
                  </span>
                </multiselect>
              </div>
            </div>

            <div class="row mb-4" v-if="selectedInstitution">
              <div class="col-sm-4 align-self-center">
                ID
              </div>
              <div class="col-sm-8 pl-0">
                {{ selectedInstitution.id }}
              </div>
            </div>
            <div class="row mb-4" v-if="selectedInstitution">
              <div class="col-sm-4 align-self-center">
                Name
              </div>
              <div class="col-sm-8 pl-0">
                <input
                  type="text"
                  name="logo"
                  id="logo"
                  class="w-100"
                  placeholder="Name"
                  v-model="selectedInstitution.name"
                />
              </div>
            </div>
            <div class="row mb-4" v-if="selectedInstitution">
              <div class="col-sm-4 align-self-center">
                Full Name
              </div>
              <div class="col-sm-8 pl-0">
                <input
                  type="text"
                  name="logo"
                  id="logo"
                  class="w-100"
                  placeholder="Full Name"
                  v-model="selectedInstitution.full_name"
                />
              </div>
            </div>
            <div class="row mb-4 pt-2" v-if="selectedInstitution">
              <div class="col-sm-12 text-center mt-5">
                <b-button
                  size=""
                  variant="primary"
                  @click="submit()"
                  class="fw-btn"
                  >Submit</b-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </super-admin-page-card>
</template>

<script>
import fiAPI from '@/modules/core/api/financial_institution'
import Multiselect from 'vue-multiselect'

export default {
  name: 'UpdateFiName',
  components: {
    Multiselect
  },
  data() {
    return {
      institutions: [],
      selectedInstitution: null,
      isLoading: false
    }
  },
  created() {
    this.searchInstitutions('')
    if (this.$route.query.fi_id) {
      this.setDefaultInstitution()
    }
  },
  computed: {},
  methods: {
    setDefaultInstitution() {
      fiAPI.show(this.$route.query.fi_id).then(res => {
        this.selectedInstitution = res.financial_institution
      })
    },
    searchInstitutions(searchText) {
      this.isLoading = true
      fiAPI
        .search(searchText, 0)
        .then(res => {
          this.institutions = res.financial_institutions
        })
        .then(() => {
          this.isLoading = false
        })
    },

    submit() {
      if (
        this.selectedInstitution.full_name &&
        this.selectedInstitution.full_name.trim() != ''
      ) {
        let formData = new FormData()

        formData.append(
          'financial_institution[id]',
          this.selectedInstitution.id
        )
        formData.append(
          'financial_institution[full_name]',
          this.selectedInstitution.full_name
        )
        formData.append(
          'financial_institution[name]',
          this.selectedInstitution.name
        )
        this.$http
          .post(
            `/api/super_admin/financial_institutions/update_fi_full_name`,
            formData,
            {
              headers: {
                'Content-Type': 'multipart/form-data'
              },
              handleErrors: true
            }
          )
          .then(() => {
            this.resetFIData()
            this.$toasted.show(
              'Full name of Financial Institution successfully updated.',
              {
                icon: 'user-circle',
                type: 'success'
              }
            )
          })
      } else {
        this.toasted.show(
          'Please enter a valid Full name for the institution.',
          {
            icon: 'chain-broken',
            type: 'error'
          }
        )
      }
    },
    resetFIData() {
      this.selectedInstitution = null
    }
  }
}
</script>

<style lang="scss">
.institution-select {
  .multiselect__single {
    font-size: 0.875rem;
    font-weight: 400;
  }
  .multiselect__option {
    font-size: 0.875rem;
    font-weight: 400;
  }
}
</style>
